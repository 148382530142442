.App {
  padding: 1rem;
}

h1 {
  margin-bottom: .125em;
}

h2 {
  font-size: 1.25em;
  font-weight: 400;
  margin-top: 0;
  opacity: .6;
}

hr {
  border: 0;
  border-top: 1px solid #ddd;
  margin: 2rem 0;
}

input[type=text] {
  font-size: 1em;
  max-width: 100%;
}

input[type=text],
textarea {
  border: 1px solid #ddd;
  border-radius: .25rem;
  padding: .5rem;
  -webkit-appearance: none;
}

.label {
  display: block;
  font-size: .75em;
  font-weight: 700;
  text-transform: uppercase;
  margin: 1rem 0 .5rem;
  opacity: .6;
}

.checkbox-container {
  font-size: 1em;
  font-weight: 400;
  margin-top: 1rem;
  position: relative;
  text-transform: none;
  opacity: 1;
}

.checkbox-container input {
  position: relative;
  bottom: .175em;
}

.hint {
  margin-top: .5em;
  font-size: .75em;
  opacity: .6;
}

.error {
  color: darkred;
  opacity: 1;
}

.alert {
  background: lightgoldenrodyellow;
  border-radius: .25rem;
  margin-top: 1rem;
  padding: 1em;
  opacity: 1;
}

.header {
  line-height: 1.125;
  position: relative;
  padding-left: 6rem;
}

.header h2 {
  font-size: 1.5em;
  margin-bottom: 0;
}

.logo {
  position: absolute;
  top: -.5rem;
  left: 0;
  height: auto;
  width: 5rem;
}

.btn {
  background: lightgrey;
  border: 1px solid lightgrey;
  box-shadow: rgba(30,30,30,.2) 0 2px 8px 0;
  border-radius: .25em;
  color: rgb(70,70,70);
  font-size: 1rem;
  padding: .5em .875em;
  -webkit-appearance: none;
}

.btn--primary {
  background: darkgreen;
  border: 1px solid darkgreen;
  box-shadow: rgba(20,40,20,.2) 0 2px 8px 0;
  color: white;
}

.btn--danger {
  background: darkred;
  border: 1px solid darkred;
  box-shadow: rgba(40,20,20,.2) 0 2px 8px 0;
  color: white;
}

.btn--sm {
  font-size: .75em;
}

.btn:active {
  transform: scale(.95);
}

section {
  margin: 2rem 0;
  width: 100%;
  word-break: break-word;
}

section h2 {
  margin-bottom: 0;
  opacity: 1;
}

section h2 + p {
  margin-top: 0;
  opacity: .6;
}

@media (min-width: 56em) {
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr;
    grid-column-gap: 2rem;
    margin-left: 6rem;
  }
}

.credits {
  font-size: .75em;
  width: 100%;
}

.form-preview {
  margin-top: 1rem;
}

.form-preview input[type=text] {
  margin-right: .5rem;
}

.form-code {
  margin: 1rem 0 .5rem;
  resize: none;
  width: 90%;
}

.color-picker {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  position: relative;
}

.color-picker .label {
  margin: 0;
}

.color-picker__selected {
  border: 2px solid #fff;
  box-shadow: 0 0 0 1px rgba(0,0,0, .1);
  border-radius: .125rem;
  margin-left: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  text-indent: -9999px;
  -webkit-appearance: none;
}

.color-picker__picker {
  position: absolute;
  bottom: 0;
  left: 0;
  padding-bottom: 2rem;
  z-index: 99;
}

.color-picker__cover {
  -webkit-appearance: none;
  border: 0;
  background: transparent;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-indent: -9999px;
}

.sketch-picker {
  background: #fff;
  position: relative;
}

.result-preview {
  border: 1px solid rgba(0,0,0,0.15);
  margin-top: 1rem;
  width: 100%;
}

.result-preview__header {
  box-shadow: 0 1px 0 rgba(0,0,0,0.15);
  padding: .75rem 1rem;
  position: relative;
}

.result-preview__body {
  padding: 1rem;
}

.result__a {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.125em;
  line-height: 1.22;
  margin-bottom: -0.05em;
  text-decoration: none;
}

.result__a:hover {
  text-decoration: underline;
}

.result__extras {
  min-height: 1.325em;
  margin: 0 0 2px;
  position: relative;
  top: 0;
}

.result__extras__url {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.result__url,
.result__url:active,
.result__url:visited:focus {
  color: #20692b;
  font-size: .875em;
  text-decoration: none;
}

.result__url:hover {
  text-decoration: underline;
}

.result__icon {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  /*margin-top: -1px;*/
  height: 16px;
  width: 16px;
  margin-right: .325em;
}

.result__icon img {
  height: 16px;
  width: 16px;
  vertical-align: top;
}

.result__snippet {
  font-size: .875em;
  line-height: 1.45;
}